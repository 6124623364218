<template>
    <div>
        <table class="table table-condensed" >
            <thead>
                <tr>
                    <th>Nomenclature</th>
                    <th>Curb ID</th>
                    <th>Crated Length</th>
                    <th>Crated Width</th>
                    <th>Crated Height</th>
                    <th>Metal Weight</th>
                    <th>Insulation Sqft</th>
                    <th>AI 1 1/4 Inch</th>
                    <th>AI 2 Inch</th>
                    <th>Gasket Roll Ct.</th>
                    <th>2x4x12 Count</th>
                    <th>Labor Hours</th>
                </tr>
            </thead>
            <tbody v-if="appState.fileUploadContextData.metadata">
                <tr v-for="curb in appState.fileUploadContextData.metadata" :key="curb.curb_id">
                    <td>{{ curb.nomenclature }}</td>
                    <td>{{ curb.curb_id }}</td>
                    <td>{{ curb.crated_length }}</td>
                    <td>{{ curb.crated_width }}</td>
                    <td>{{ curb.crated_height }}</td>
                    <td>{{ curb.cogs_metal_weight }}</td>
                    <td>{{ curb.insulation_sqft }}</td>
                    <td>{{ curb.one_and_qtr_in_angle_iron_by_ft }}</td>
                    <td>{{ curb.two_in_angle_iron_by_ft }}</td>
                    <td>{{ curb.gasket_roll_ct }}</td>
                    <td>{{ curb.lumber_2x4x12_by_board }}</td>
                    <td>{{ curb.cogs_labor_time }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import {fileUploadStore} from "@/store/FileUpload.store";
import appStore from "@/store/App.store";

export default {
    data() {
        return {
            state: fileUploadStore.state,
            appState: appStore.state
        }
    },
    created() {
        fileUploadStore.initialize();
    },
    mounted() {
        fileUploadStore.loadMetadata();
    }
}

</script>